import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In bikers terms there are so many trails in the mountains like stars in the
sky. Every now and then we discover one that leaves us speechless. These we
want to share with you here. The list has no rated sequence.`}</p>
    <h2>{`1 Gallinero`}</h2>
    <h4>{`Benasque, Spain (8,2km, 1090vm)`}</h4>
    <Image src="news/toptrailgallinero.jpg" mdxType="Image" />
    <p>{`Awesome long trail with over 1000 vm descent in constantly changing terrain.
With a stunning view the high speed traverse starts and slowly merges into
great technical sections on unique red rock. The lower part completes the
rush with fast corners on grippy forest floor through the woods. A real gem
in the Benasque valley.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2479"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`2 Matajur`}</h2>
    <h4>{`Kobarid/ San Pietro, Slovenia/ Italy (11,8km, 1740vm)`}</h4>
    <Image src="news/toptrailmatajur.jpg" mdxType="Image" />
    <p>{`At the border of Slovenia and Italy a long trail with almost 1740 vm descent
starts from the Matjur. It follows the endless, slightly falling ridge towards
San Pietro on the Italien side. The descent is on the flowy side, but with
rocky parts at the top and nice technical sections lower down in the woods.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1749"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`3 Trais Fluors`}</h2>
    <h4>{`St. Moritz, Switzerland (12km, 1260vm)`}</h4>
    <Image src="news/toptrailtraisfluors.jpg" mdxType="Image" />
    <p>{`After you climbed up in the thin air, the first meters feel like you are on
the moon, riding on white, fine gravel. Soon you reach idyllic meadows and
fly on the slightly descending and amazing flowy trail towards the Alp
Muntatsch. From here on you can enjoy a beautiful trail with many roots
through the forest down to Bevers.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2035"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`4 Bettmersee & Slick Rock`}</h2>
    <h4>{`Brig, Switzerland (15,1km, 1930vm)`}</h4>
    <Image src="news/toptrailaletsch.jpg" mdxType="Image" />
    <p>{`Alpine and technical ridge with stunning views onto the biggest glacier of
the Alps at the top. Fast and flowy middle part along the Bettmersee and
challenging lower half through the steep woods into the Rhone valley.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2270"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`5 Visperterminen`}</h2>
    <h4>{`Visp, Switzerland (10,3 km, 1280vm)`}</h4>
    <Image src="news/toptrailvisperterminen.jpg" mdxType="Image" />
    <p>{`Long trail through the woods above Visp. Easy along ancient water channels
in the upper part, steep through the forest in the middle and high speed
between the switchbacks at the end, the trail will leave you breathless.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2300"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`6 Pierre Avio`}</h2>
    <h4>{`Verbier, Switzerland (2,5km, 440vm)`}</h4>
    <Image src="news/toptrailpierreavoi.jpg" mdxType="Image" />
    <p>{`The alpine trail starts with a breathtaking view high above Verbier and the
rhone valley. It descents through many easy switchbacks and along big cliffs
before entering the forest and becoming steep, exposed and technical.
Great trail for experienced riders.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2224"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`7 Rock’n’roll Queen`}</h2>
    <h4>{`Massa Marittima, Italy (3km, 410vm)`}</h4>
    <Image src="news/toptrailrocknrollqueen.jpg" mdxType="Image" />
    <p>{`The queen of all trails. A trail you still feel when you are already in your
bed again. The rhythm of the corners and obstacles carries you down on a
cloud made of the purest natural flow. The most awesome 400 vm we rode in
a very long time.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/1428"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`8 Maxiavalanche V2`}</h2>
    <h4>{`Aínsa, Spain (14,7km, 1060vm`}</h4>
    <Image src="news/toptrailmaxiavalanche.jpg" mdxType="Image" />
    <p>{`Never ending trail through all sorts of terrain. Over typical spanish loose
rocks at the top, rhythm sections with great turns in the woods and over
gravel ridges - the famous Margas - all the way down to Aínsa.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2485"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`9 Alguacil`}</h2>
    <h4>{`Güejar Sierra, Spain (7,6km, 690vm)`}</h4>
    <Image src="news/toptrailalguacil.jpg" mdxType="Image" />
    <p>{`Great trail for everyone who loves rocks. Always changing between flowy
and technical sections, between loose and compact rocks. The snowy peaks of
the Sierra Nevada always to your left.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2538"
      }}>{`read more...`}</a></p>
    <br />
    <h2>{`10 La Techa & Foresral & Canada Arco`}</h2>
    <h4>{`Alhaurin de la Torre, Spain (5,4km, 710vm)`}</h4>
    <Image src="news/toptraillatecha.jpg" mdxType="Image" />
    <p>{`Close to the Mediterranean sea this is a combination of three consecutive
trails. La Techa, super technical with awesome rock sections, then easier
and faster but always with a good portion of rocks.
`}<a parentName="p" {...{
        "href": "https://trailguide.net/2621"
      }}>{`read more...`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      